import { Outlet } from "react-router-dom";
import Navbar from "./NavbarSite/Navbar";
import {
  Ticket,
  getMessageFromFetchError,
  useAllTariffsFetcher,
  usePrivilegeFetcher,
  useSSESubscription,
  useTicketsFetcher,
  useToken,
} from "@frontend/kitui";
import {
  updateTickets,
  setTicketCount,
  useTicketStore,
  setTicketsFetchState,
} from "@root/stores/tickets";
import { enqueueSnackbar } from "notistack";
import { updateTariffs } from "@root/stores/tariffs";
import { useCustomTariffs } from "@root/utils/hooks/useCustomTariffs";
import { setCustomTariffs } from "@root/stores/customTariffs";
import { setPrivileges } from "@root/stores/privileges";
import { useHistoryData } from "@root/utils/hooks/useHistoryData";
import { useSSETab } from "@root/utils/hooks/useSSETab";

export default function ProtectedLayout() {
  const token = useToken();
  const ticketApiPage = useTicketStore((state) => state.apiPage);
  const ticketsPerPage = useTicketStore((state) => state.ticketsPerPage);
  const { isActiveSSETab, updateSSEValue } = useSSETab<Ticket[]>(
    "auth",
    (data) => {
      updateTickets(data.filter((d) => Boolean(d.id)));
      setTicketCount(data.length);
    }
  );
  useSSESubscription<Ticket>({
    enabled: isActiveSSETab,
    url:
      process.env.REACT_APP_DOMAIN +
      `/heruvym/v1.0.0/subscribe?Authorization=${token}`,
    onNewData: (data) => {
      updateSSEValue(data);
      updateTickets(data.filter((d) => Boolean(d.id)));
      setTicketCount(data.length);
    },
    marker: "ticket",
  });

  useTicketsFetcher({
    url: process.env.REACT_APP_DOMAIN + "/heruvym/v1.0.0/getTickets",
    ticketsPerPage,
    ticketApiPage,
    onSuccess: (result) => {
      if (result.data) updateTickets(result.data);
      setTicketCount(result.count);
    },
    onError: (error: Error) => {
      const message = getMessageFromFetchError(error);
      if (message) enqueueSnackbar(message);
    },
    onFetchStateChange: setTicketsFetchState,
  });

  useAllTariffsFetcher({
    baseUrl: process.env.REACT_APP_DOMAIN + "/strator/tariff/getList",
    onSuccess: updateTariffs,
    onError: (error) => {
      const errorMessage = getMessageFromFetchError(error);
      if (errorMessage) enqueueSnackbar(errorMessage);
    },
  });

  useCustomTariffs({
    onNewUser: setCustomTariffs,
    onError: (error) => {
      if (error) enqueueSnackbar(error);
    },
  });

  usePrivilegeFetcher({
    url: process.env.REACT_APP_DOMAIN + "/strator/privilege/service",
    onSuccess: setPrivileges,
    onError: (error) => {
      console.error("usePrivilegeFetcher error :>> ", error);
    },
  });

  useHistoryData();

  return (
    <Navbar>
      <Outlet />
    </Navbar>
  );
}
